<template>
  <v-main class="mt-3 container-prosker">
    <v-row>
      <v-col cols="12" md="3">
        <facet-options
          :update-results="updateResults"
          :url-params="globalSearch.categoryIds"
          :search-text="globalSearch.user"
        />
      </v-col>
      <v-col cols="12" md="9">
        <div v-if="loading">
          <v-skeleton-loader
            v-for="index in 5"
            :key="index"
            v-bind="skeletonAttrs"
            type="list-item-avatar, divider, list-item-three-line, card-heading, actions"
          ></v-skeleton-loader>
        </div>
        <div v-if="usersData.length">
            <v-card  v-for="(item, i) in usersData" :key="i" class="border-radius-medium mb-5">
              <v-card-text>
                <v-row>
                  <v-col cols="4" md="2" class="text-center">
                    <v-avatar width="7rem" height="7rem">
                      <img
                        v-if="!item.profile_img"
                        src="@/assets/images/page-img/avatar.png"
                        class="ounded-circle border border-dark avatar-90"
                        alt="user"
                      />
                      <img
                        v-else
                        :src="item.profile_img"
                        class="rounded-circle border border-dark avatar-90"
                        alt="user"
                      />
                    </v-avatar>
                  </v-col>
                  <v-col cols="8" md="10">
                    <v-row class="pt-2">
                      <v-col cols="8">
                        <v-row>
                          <span
                            class="
                              mb-0
                              me-1
                              secondary--text
                              font-weight-bold
                              pointer-cursor
                            "
                            @click="goToUserProfile(item)"
                          >
                              {{ item.first_name }} {{ item.last_name }}
                          </span>
                        </v-row>
                        <v-row v-if="false">
                          <span class="green-prosk">{{ item.count_works_done }} {{ $t('worksDone') }}</span>
                        </v-row>
                        <v-row>
                          <v-rating
                            v-model="rating"
                            background-color="title_highlight_color"
                            color="title_highlight_color"
                            readonly
                            half-increments
                            dense
                            size="1rem"
                          ></v-rating>
                        </v-row>
                        <v-row>
                          <v-chip-group column>
                            <v-chip
                              v-for="(category, i) in item.category_name"
                              :key="i"
                              small
                              class=" cursor-auto"
                              color="primary_light"
                              text-color="main_text_color"
                            >
                              {{ category }}
                            </v-chip>
                          </v-chip-group>
                        </v-row>
                      </v-col>
                      <v-col class="d-none d-md-block">
                        <span v-show="currentUser">
                          <v-btn
                            icon
                            class="pointer-cursor background_contrast"
                            @click="toggleFavorite(item, false)"
                            v-if="item.favorites.length > 0"
                          >
                            <img src="@/assets/images/page-img/favorito-02.svg"  />
                          </v-btn>
                          <v-btn class="pointer-cursor" icon @click="toggleFavorite(item, true)" v-else>
                            <img src="@/assets/images/page-img/favorito.svg"  />
                          </v-btn>
                        </span>
                        <v-btn
                          icon
                          class="pointer-cursor background_contrast ml-2 mr-2"
                          small
                          @click="createConversation(item.id, item.username, item)"
                        >
                          <img src="@/assets/images/page-img/mensaje.svg"  />
                        </v-btn>
                        <general-button :loading="false" message="Comprar" size="medium" secondary  :action="currentUser ? ()=>goToUserProfile(item, $options.CALENDAR_TAB) : ()=> goToUserProfile(item)"/>
                      </v-col>
                    </v-row>
                    <v-row class="pt-2">
                      <v-col cols="12" md="10">
                        <div class="pr-5">
                          <span>{{ item.description }}</span>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row class="mt-2 me-1 mb-3">
                      <v-chip-group column>
                        <v-chip
                            v-for="(skill, i) in item.skill_name"
                            :key="i"
                            small
                            outlined
                            class="ma-2 cursor-auto"
                            color="title_highlight_color"
                        >
                          {{ skill }}
                        </v-chip>
                      </v-chip-group>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-col class="d-flex d-md-none justify-content-end align-items-center">
                <span v-show="currentUser">
                  <v-btn
                    icon
                    class="pointer-cursor background_contrast"
                    @click="toggleFavorite(item, false)"
                    v-if="item.favorites.length > 0"
                  >
                    <img src="@/assets/images/page-img/favorito-02.svg"  />
                  </v-btn>
                  <v-btn class="pointer-cursor background_contrast" icon @click="toggleFavorite(item, true)" v-else>
                    <img src="@/assets/images/page-img/favorito.svg"  />
                  </v-btn>
                </span>
                  <v-btn
                    icon
                    class="pointer-cursor ml-2 mr-2"
                    small
                    @click="createConversation(item.id, item.username, item)"
                  >
                    <img src="@/assets/images/page-img/mensaje.svg"  />
                  </v-btn>
                  <general-button :loading="false" message="Comprar" size="medium" secondary  :action="currentUser ? ()=>goToUserProfile(item, $options.CALENDAR_TAB) : ()=> goToUserProfile(item)"/>
              </v-col>
            </v-card>
            <v-pagination
                  v-model="page"
                  color="#59D79D"
                  :length="lastPage"
                  circle
            ></v-pagination>
          </div>
          <alert :message="$t('noResults')" v-else/>
      </v-col>
    </v-row>
  </v-main>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import FacetOptions from '@/components/socialvue/filters/FacetOptions.vue';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import Alert from '@/components/socialvue/alerts/Alert.vue';
import goToUserProfile from '@/components/socialvue/mixins/goToUserProfile';
import userDataMixin from '@/mixins/userDataMixin';
import { FACET_CATEGORY_FIELD_NAME, FACET_SKILLS_FIELD_NAME, FACET_CITY_FIELD_NAME } from '@/misc/constants';
import GeneralButton from '@/components/socialvue/buttons/GeneralButton.vue';
import conversationsMixin from '@/mixins/conversationsMixin';

export default {
  name: 'UserFavorites',
  components: { Alert, FacetOptions, GeneralButton },
  mixins: [
    errorDialog,
    goToUserProfile,
    userDataMixin,
    conversationsMixin
  ],
  data () {
    return {
      page: 1,
      lastPage: 1,
      facetOptionsSelected: [],
      skeletonAttrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2
      },
      loading: true,
      showScheduler: false,
      globalSearch: {},
      rating: 5,
      usersData: []
    };
  },
  created () {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
    this.getUsersFavorites();
    this.globalSearch = {};
    this.globalSearch = JSON.parse(this.$route.params.query);
    if (this.globalSearch.categoryIds && this.globalSearch.categoryIds.length) {
      this.globalSearch.categoryIds.forEach((categoryId) => {
        this.facetOptionsSelected.push({
          field_name: 'categories.id',
          value: categoryId,
          id: null
        });
      });
    }
    this.showCalendar();
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  watch: {
    facetOptionsSelected: function (value) {
      if (value) {
        this.page = 1;
      }
    },
    page: function (value) {
      if (value) {
        this.getUsersFavorites();
      }
    },
    $route (to) {
      if (to.params.query !== this.globalSearch) {
        this.globalSearch = JSON.parse(to.params.query);
        this.getUsersFavorites();
      }
    }
  },
  methods: {
    showCalendar () {
      if (this.currentUser) {
        this.currentUser.active_subscription.forEach((subscription) => {
          this.showScheduler = subscription.cost !== 0;
        });
      }
    },
    updateResults (data) {
      this.facetOptionsSelected = data;
      this.getUsersFavorites();
    },
    toggleFavorite (userId, bool) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/favorites`;
      if (bool) {
        const data = {
          favorite_user_id: userId,
          user_id: this.currentUser.id
        };
        axios
          .post(url, data)
          .then(() => {
            this.getUsersFavorites();
          })
          .catch((error) => {
            this.showError(error);
          });
      } else {
        axios
          .delete(url, {
            data: {
              favorite_user_id: userId
            }
          })
          .then(() => {
            this.sendDataLayerUnfavorite(userId);
            this.getUsersFavorites();
          })
          .catch((error) => {
            this.showError(error);
          });
      }
    },
    getURL () {
      if (!this.currentUser) {
        return;
      }

      const urlFilters = {};
      let filterQuery = '';
      this.facetOptionsSelected.forEach((facetOption) => {
        if (urlFilters[facetOption.field_name]) {
          urlFilters[facetOption.field_name].push(facetOption.value);
        } else {
          urlFilters[facetOption.field_name] = [facetOption.value];
        }
      });
      if (JSON.stringify(urlFilters) !== '{}') {
        for (let prop in urlFilters) {
          filterQuery += `${prop}=${urlFilters[prop].join('|')}|,`;
        }
        filterQuery += `profile_img=notNull`;
      }
      if (this.facetOptionsSelected.length !== 0) {
        return `${process.env.VUE_APP_BASE_URL}/api/users?filters=favorites.user_id=${this.currentUser.id}|,${filterQuery}&page=${this.page}`;
      } else {
        return `${process.env.VUE_APP_BASE_URL}/api/users?filters=favorites.user_id=${this.currentUser.id}|&page=${this.page}`;
      }
    },
    sendDataLayerInfo () {
    const categories = this.facetOptionsSelected.filter(item => item.field_name === FACET_CATEGORY_FIELD_NAME).map(item => item.display_name);
    const skills = this.facetOptionsSelected.filter(item => item.field_name === FACET_SKILLS_FIELD_NAME).map(item => item.display_name);
    const cities = this.facetOptionsSelected.filter(item => item.field_name === FACET_CITY_FIELD_NAME).map(item => item.display_name);
      this.trackUserData('load_content', {
        pageType: 'favorites',
        categories,
        modalidades: skills,
        localidades: cities
      });
    },
    sendDataLayerUnfavorite (user) {
      this.trackUserData('click_unfavorite', {
       prosker_name: user.first_name + ' ' + user.last_name
      });
    },
    getUsersFavorites () {
      this.usersData = [];
      this.loading = true;
      axios
        .get(this.getURL())
        .then((response) => {
          response.data.data.forEach((user) => {
            this.usersData.push(user);
          });
          this.lastPage = response.data.last_page;
          this.usersData = this.usersData.filter(
            (user) => user.id !== this.currentUser.id
          );
          this.sendDataLayerInfo();
          this.loading = false;
        })
        .catch((error) => {
          this.showError(error);
          this.loading = false;
        });
    }
  }
};
</script>
